/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage/preview */
import React from 'react';

import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';

export const AtlassianIntelligenceLogoDark = (props: GlyphProps) => (
	<AtlassianIntelligenceIcon color={token('color.icon')} {...props} />
);

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CatchupContentType {
  BLOGPOST = "BLOGPOST",
  PAGE = "PAGE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CatchupLastViewedTimeQuery
// ====================================================

export interface CatchupLastViewedTimeQuery_catchupGetLastViewedTime {
  lastViewedTimeISO: string | null;
}

export interface CatchupLastViewedTimeQuery {
  catchupGetLastViewedTime: CatchupLastViewedTimeQuery_catchupGetLastViewedTime | null;
}

export interface CatchupLastViewedTimeQueryVariables {
  contentId: string;
  contentType: CatchupContentType;
}

import React, { useContext, useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import { FlagsProvider } from '@atlaskit/flag';

import type { AIEventsInstrumentationConfig } from '@atlassian/ai-analytics';
import {
	AIEventsInstrumentationProvider,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { ExperienceTrackerContext, AI_AUDIO } from '@confluence/experience-tracker';
import {
	SmartsFeedbackComponent,
	FeedbackCollectorPropertiesProvider,
} from '@confluence/quick-summary';

import { useAudioPlaybackState } from '../hooks/useAudioPlaybackState';

import type {
	AudioPanelContentQuery as AudioPanelContentQueryType,
	AudioPanelContentQueryVariables,
} from './__types__/AudioPanelContentQuery';
import { Miniplayer } from './Miniplayer';
import { AudioPanelErrorComponent } from './AudioPanelErrorComponent';

const AudioPanelContentQuery = gql`
	query AudioPanelContentQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			title
			type
			subType
			space {
				name
			}
		}
	}
`;

const feedbackContainer = xcss({
	paddingInline: 'space.200',
});

const i18n = defineMessages({
	sendFeedback: {
		id: 'audio.panel.sendFeedbackLabel',
		defaultMessage: 'Send feedback',
		description: 'Send feedback label text',
	},
});

const aiAnalyticsConfig: AIEventsInstrumentationConfig = {
	product: 'confluence',
	subproduct: 'confluence',
	source: 'page',
	aiFeatureName: 'aiAudio',
	proactiveGeneratedAI: 0,
	userGeneratedAI: 1,
};

const AUDIO_FEEDBACK_ENTRYPOINT_ID = 'cca5a6aa-d4e5-4195-8927-eb10c3620ff8';
const ANALYTICS_SOURCE = 'aiAudio';

export const AudioPanelComponent = () => {
	const [{ activeContent, isLoading, error }, { updateActiveContent }] = useAudioPlaybackState();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { trackAIInteractionInit, trackAIResultError } = useAIEventsInstrumentation();
	const hasLoaded = useRef(false);
	const { formatMessage } = useIntl();

	const { data, error: contentQueryError } = useQuery<
		AudioPanelContentQueryType,
		AudioPanelContentQueryVariables
	>(AudioPanelContentQuery, {
		variables: {
			contentId: activeContent?.id ?? '',
		},
		skip: !activeContent?.id,
	});

	useEffect(() => {
		if (data) {
			updateActiveContent({
				contentTitle: data?.singleContent?.title,
				spaceName: data?.singleContent?.space?.name,
				contentType: data?.singleContent?.type,
				contentSubType: data?.singleContent?.subType,
			});
		}
	}, [data, updateActiveContent]);

	useEffect(() => {
		if (isLoading) {
			hasLoaded.current = true;
			experienceTracker.start({
				name: AI_AUDIO,
				attributes: {
					contentId: activeContent?.id,
				},
			});
			trackAIInteractionInit();
		} else if (hasLoaded.current) {
			if (error) {
				experienceTracker.fail({ name: AI_AUDIO, error });
				trackAIResultError({
					aiErrorMessage: error.message,
				});
			} else {
				experienceTracker.succeed({ name: AI_AUDIO });
			}
		}
	}, [
		activeContent,
		error,
		experienceTracker,
		isLoading,
		hasLoaded,
		trackAIInteractionInit,
		trackAIResultError,
	]);

	const combinedError = error || contentQueryError;

	if (combinedError) {
		return <AudioPanelErrorComponent error={combinedError} />;
	}

	return (
		<Stack space="space.600">
			<Miniplayer />
			<Inline alignBlock="center" space="space.100" xcss={feedbackContainer}>
				<Text color="color.text.subtlest" size="small">
					{formatMessage(i18n.sendFeedback)}
				</Text>
				<SmartsFeedbackComponent
					contentId={activeContent?.id ?? ''}
					analyticsProperties={{
						source: ANALYTICS_SOURCE,
						additionalAnalyticsAttributes: {
							contentType: activeContent?.contentType ?? '',
						},
					}}
				/>
			</Inline>
		</Stack>
	);
};

export const AudioPanel = () => (
	<ErrorBoundary attribution={Attribution.SMARTS}>
		<FlagsProvider>
			<FeedbackCollectorPropertiesProvider entrypointId={AUDIO_FEEDBACK_ENTRYPOINT_ID}>
				<AIEventsInstrumentationProvider config={aiAnalyticsConfig}>
					<AudioPanelComponent />
				</AIEventsInstrumentationProvider>
			</FeedbackCollectorPropertiesProvider>
		</FlagsProvider>
	</ErrorBoundary>
);

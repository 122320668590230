import { styled } from '@compiled/react';

import { N0, N60A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptInBackgroundBorder = styled.div<{ width?: number }>({
	overflow: 'hidden',
	boxShadow: `${token('elevation.shadow.overlay', `0 4px 8px -2px ${N60A}, 0 0 1px ${N60A}`)}`,

	borderRadius: '5px',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => (props.width ? `${props.width}px` : '450px'),
	paddingTop: token('space.025', '2px'),
	paddingRight: token('space.025', '2px'),
	paddingBottom: token('space.025', '2px'),
	paddingLeft: token('space.025', '2px'),
	position: 'relative',

	background: `conic-gradient(
          from 90deg,
          #1d7afc 10%,
          #8bdbe5 30%,
          #f8e6a0 50%,
          #8bdbe5 70%,
          #1d7afc 90%
        )`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptInContainer = styled.div({
	borderRadius: '3px',
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: token('elevation.surface.overlay', N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ImageWrapper = styled.div({
	borderRadius: '3px 3px 0 0',
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	img: {
		objectFit: 'cover',
		width: '100%',
		borderRadius: '3px 3px 0 0',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentContainer = styled.div({
	display: 'flex',
	paddingTop: token('space.300', '24px'),
	paddingRight: token('space.300', '24px'),
	paddingBottom: token('space.300', '24px'),
	paddingLeft: token('space.300', '24px'),
	flexDirection: 'column',
	alignSelf: 'stretch',
	gap: token('space.100', '8px'),
	alignItems: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderWrapper = styled.div({
	font: token('font.heading.small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BodyWrapper = styled.div({
	margin: '0',
	font: token('font.body'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ButtonContainer = styled.div({
	display: 'flex',
	width: '100%',
	marginTop: token('space.100', '8px'),
	justifyContent: 'flex-end',
	gap: token('space.050', '4px'),
});

import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import VideoSkipBackwardFifteenIcon from '@atlaskit/icon/core/video-skip-backward-fifteen';
import VideoSkipForwardFifteenIcon from '@atlaskit/icon/core/video-skip-forward-fifteen';
import VideoPreviousIcon from '@atlaskit/icon/core/video-previous';
import { Flex, Grid } from '@atlaskit/primitives';

import { PlayPauseButton } from './PlayPauseButton';
import { MiniplayerPlaybackSpeedControl } from './MiniplayerPlaybackSpeedControl';

const i18n = defineMessages({
	skipBackButtonLabel: {
		id: 'audio.miniplayer-controls.skip-back-label',
		defaultMessage: 'Skip Back',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will skip the currently active audio back "x" seconds',
	},
	skipForwardButtonLabel: {
		id: 'audio.miniplayer-controls.skip-forward-label',
		defaultMessage: 'Skip Forward',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will skip the currently active audio forward "x" seconds',
	},
	moreButtonLabel: {
		id: 'audio.miniplayer-controls.more-label',
		defaultMessage: 'More',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will open additional playback controls for the active audio',
	},
	playFromBeginningLabel: {
		id: 'audio.miniplayer-controls.play-from-beginning-label',
		defaultMessage: 'Play from beginning',
		description:
			'The label is used for accessibility for the icon which will be shown in the menu item that will restart the active audio from the beginning',
	},
});

export type MiniplayerControlsProps = {
	onPause: () => void;
	onResume: () => Promise<void>;
	onSkipBack: () => void;
	onSkipForward: () => void;
	onPlayFromBeginning: () => Promise<void>;
};

export const MiniplayerControls: FC<MiniplayerControlsProps> = ({
	onPause,
	onResume,
	onSkipBack,
	onSkipForward,
	onPlayFromBeginning,
}) => {
	const intl = useIntl();

	return (
		<Grid templateColumns="1fr auto 1fr" testId="miniplayer-controls">
			<Flex justifyContent="space-evenly" alignItems="center">
				<IconButton
					icon={VideoPreviousIcon}
					shape="circle"
					label={intl.formatMessage(i18n.playFromBeginningLabel)}
					tooltip={{ content: intl.formatMessage(i18n.playFromBeginningLabel) }}
					appearance="subtle"
					testId="miniplayer-play-from-beginning"
					onClick={onPlayFromBeginning}
				/>
				<IconButton
					icon={VideoSkipBackwardFifteenIcon}
					shape="circle"
					label={intl.formatMessage(i18n.skipBackButtonLabel)}
					tooltip={{ content: intl.formatMessage(i18n.skipBackButtonLabel) }}
					isTooltipDisabled={false}
					appearance="subtle"
					testId="miniplayer-skip-back"
					onClick={onSkipBack}
				/>
			</Flex>
			<PlayPauseButton onPause={onPause} onResume={onResume} />
			<Grid templateColumns="1fr 1fr">
				<Flex justifyContent="center" alignItems="center">
					<IconButton
						icon={VideoSkipForwardFifteenIcon}
						shape="circle"
						label={intl.formatMessage(i18n.skipForwardButtonLabel)}
						tooltip={{ content: intl.formatMessage(i18n.skipForwardButtonLabel) }}
						isTooltipDisabled={false}
						appearance="subtle"
						testId="miniplayer-skip-forward"
						onClick={onSkipForward}
					/>
				</Flex>
				<Flex justifyContent="center" alignItems="center">
					<MiniplayerPlaybackSpeedControl />
				</Flex>
			</Grid>
		</Grid>
	);
};

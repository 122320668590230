import { styled } from '@compiled/react';
import React, { forwardRef } from 'react';

import type { PopupComponentProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':focus': {
		outline: 'none',
	},
	backgroundColor: `${token('elevation.surface.raised')}`,
	border: `.5px solid ${token('color.border')}`,
	borderRadius: '12px',
	boxShadow: `${token('elevation.shadow.overlay')}`,
	width: '345px',
});

export const AIFloatingContextMenuPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, ...props }, ref) => {
		return (
			<PopupWrapper {...props} ref={ref} role="dialog">
				{children}
			</PopupWrapper>
		);
	},
);

import { useEffect, useRef, useState } from 'react';

import {
	useSetOnboardingState,
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_INSIGHTS } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useSessionData } from '@confluence/session-data';
import { useLiveDocChangeboarding } from '@confluence/onboarding-live-doc-changeboarding/entry-points/useLiveDocChangeboarding';

import { CatchupContentType } from '../__types__/CatchupLastViewedTimeQuery';

import { useDynamicSplitButton } from './useDynamicSplitButton';

type UseAIFloatingButtonNudgeReturn = {
	pulseCount: number;
};

export const useAIFloatingButtonNudge = (
	contentId: string,
	contentType: string,
): UseAIFloatingButtonNudgeReturn => {
	const { setOnboardingState } = useSetOnboardingState();
	const [pulseCount, setPulseCount] = useState(0);
	const hasLoaded = useRef(false);
	const { isAdminHubAIEnabled } = useSessionData();
	const {
		aiButtonTour: { isVisible: isChangeboardingVisible },
	} = useLiveDocChangeboarding();

	const contentTypeEnum =
		contentType === 'page' ? CatchupContentType.PAGE : CatchupContentType.BLOGPOST;

	const { showCatchupAsDefault, queryLoading: dynamicSplitButtonLoading } = useDynamicSplitButton(
		contentId,
		contentTypeEnum,
	);

	// Ignoring error state as we will just not show a pulse if this errors
	const { data: onboardingStateData, loading: onboardingStateLoading } = useGetOnboardingState(
		ONBOARDING_INSIGHTS.CONTEXTUAL_FLOATING_BUTTON_NUDGE,
		!isAdminHubAIEnabled,
	);

	useEffect(() => {
		if (
			!dynamicSplitButtonLoading &&
			!onboardingStateLoading &&
			!hasLoaded.current &&
			isAdminHubAIEnabled &&
			!isChangeboardingVisible
		) {
			// We only want this to execute once and only once when both hooks are finished loading
			hasLoaded.current = true;

			const { hasContextualFloatingButtonBeenSeen } = deserializeState(onboardingStateData);

			if (onboardingStateData != null && !hasContextualFloatingButtonBeenSeen) {
				setOnboardingState({
					key: ONBOARDING_INSIGHTS.CONTEXTUAL_FLOATING_BUTTON_NUDGE,
					value: 'true',
				});
				setPulseCount(2);
			} else if (showCatchupAsDefault) {
				setPulseCount(1);
			}
		}
	}, [
		dynamicSplitButtonLoading,
		onboardingStateData,
		onboardingStateLoading,
		setPulseCount,
		setOnboardingState,
		showCatchupAsDefault,
		isAdminHubAIEnabled,
		isChangeboardingVisible,
	]);

	return { pulseCount };
};

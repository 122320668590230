/**
 * @graphql cc
 */
import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import { useSessionData } from '@confluence/session-data';
import { expValEquals } from '@confluence/feature-experiments';

import { CatchupLastViewedTimeQuery } from '../CatchupLastViewedTimeQuery.graphql';
import type {
	CatchupContentType,
	CatchupLastViewedTimeQuery as CatchupLastViewedTimeQueryResponse,
	CatchupLastViewedTimeQueryVariables,
} from '../__types__/CatchupLastViewedTimeQuery';
import type {
	LastModifiedDateQuery as LastModifiedDateQueryResponse,
	LastModifiedDateQueryVariables,
} from '../__types__/LastModifiedDateQuery';
import { LastModifiedDateQuery } from '../LastModifiedDateQuery.graphql';

type DynamicSplitButtonReturnType = {
	showCatchupAsDefault: boolean;
	queryLoading: boolean;
	queryError?: ApolloError | undefined;
};

export const useDynamicSplitButton = (
	contentId: string,
	contentType: CatchupContentType,
): DynamicSplitButtonReturnType => {
	const { isAdminHubAIEnabled } = useSessionData();

	const {
		data,
		loading: queryLoading,
		error: queryError,
	} = useQuery<CatchupLastViewedTimeQueryResponse, CatchupLastViewedTimeQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CatchupLastViewedTimeQuery,
		{
			variables: {
				contentId,
				contentType,
			},
			skip:
				!expValEquals('cc_ai_consumption_ux_button', 'button-placement', 'bottom') ||
				!isAdminHubAIEnabled,
		},
	);

	const { data: lastModifiedDateData } = useQuery<
		LastModifiedDateQueryResponse,
		LastModifiedDateQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		LastModifiedDateQuery,
		{
			variables: {
				contentId,
			},
			skip:
				!expValEquals('cc_ai_consumption_ux_button', 'button-placement', 'bottom') ||
				!isAdminHubAIEnabled,
		},
	);

	const lastModifiedDate =
		lastModifiedDateData?.content?.nodes?.[0]?.metadata?.lastModifiedDate ?? '';
	const lastViewedTime = data?.catchupGetLastViewedTime?.lastViewedTimeISO ?? '';
	const parsedLastViewedTime = Date.parse(lastViewedTime);
	const parsedLastModifiedDate = Date.parse(lastModifiedDate);

	if (!parsedLastViewedTime || !parsedLastModifiedDate || queryError) {
		return {
			showCatchupAsDefault: false,
			queryLoading,
			queryError,
		};
	}

	const showCatchupAsDefault = parsedLastModifiedDate > parsedLastViewedTime;

	return {
		showCatchupAsDefault,
		queryLoading,
	};
};

import { keyframes, styled, css } from '@compiled/react';
import type { ReactNode } from 'react';
import React from 'react';

import { token } from '@atlaskit/tokens';
import { useIsReducedMotion } from '@atlaskit/motion';

const baseShadow = `0 0 0 2px ${token('color.border.brand')}`;

const easing = 'cubic-bezier(0.55, 0.055, 0.675, 0.19)';
const pulseKeyframes = keyframes({
	'0%, 33%': {
		boxShadow: `${baseShadow}, 0 0 0 ${token('color.border.brand')}`,
	},
	'66%, 100%': {
		boxShadow: `${baseShadow}, 0 0 0 10px rgba(101, 84, 192, 0.01)`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SinglePulse = styled.div({
	animation: `${pulseKeyframes} 3000ms ${easing} 1`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const DoublePulse = styled.div({
	animation: `${pulseKeyframes} 3000ms ${easing} 2`,
});

const defaultBorderRadius = css({
	borderRadius: token('border.radius.300'),
});

const isInObjectSideBarBorderRadius = css({
	borderRadius: '64px',
});

type CustomPulseProps = {
	pulseCount?: number;
	isInObjectSideBar?: boolean;
	children: ReactNode;
};

export const ContextualButtonPulse = ({
	children,
	pulseCount = 0,
	isInObjectSideBar = false,
}: CustomPulseProps) => {
	const isReducedMotion = useIsReducedMotion();

	if (isReducedMotion) {
		return <div data-testid="contextual-pulse-none">{children}</div>;
	}

	switch (pulseCount) {
		case 1:
			return (
				<SinglePulse
					css={[defaultBorderRadius, isInObjectSideBar && isInObjectSideBarBorderRadius]}
					data-testid="contextual-pulse-single"
				>
					{children}
				</SinglePulse>
			);
		case 2:
			return (
				<DoublePulse
					css={[defaultBorderRadius, isInObjectSideBar && isInObjectSideBarBorderRadius]}
					data-testid="contextual-pulse-double"
				>
					{children}
				</DoublePulse>
			);
	}

	return <div data-testid="contextual-pulse-none">{children}</div>;
};

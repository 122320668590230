/** @jsx jsx */
/** @jsxFrag */
import React, { useCallback, useState, type FC, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl-next';
import { css, jsx } from '@compiled/react';
import type { MessageDescriptor } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';

import { isFedRamp } from '@atlassian/atl-context';

import { useLiveDocChangeboarding } from '@confluence/onboarding-live-doc-changeboarding/entry-points/useLiveDocChangeboarding';
import { SPAViewContext } from '@confluence/spa-view-context';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { CommentCountsProvider } from '@confluence/comment-context';
import { CustomPopupContainer } from '@confluence/quick-summary';
import { useSessionData } from '@confluence/session-data';
import { useQuickstartEligible, useQuickstartState } from '@confluence/onboarding-quickstart';
import { useIsAIEnabledForContent } from '@confluence/ai-common/entry-points/useIsAIEnabledForContent';
import { LoadableBackground } from '@confluence/loadable';

import { ContextualButtonPulse } from './platform-ui/ContextualButtonPulse';
import { useDynamicAIContext } from './hooks/useDynamicAIContext';
import { useAIFloatingButtonNudge } from './hooks/useAIFloatingButtonNudge';
import { AIFloatingSplitButton } from './platform-ui/AIFloatingSplitButton';
import { AIFloatingContextMenuPopupContainer } from './AIFloatingContextMenuPopupContainer';
import { i18n as i18nDynamicAction } from './AIFloatingContextMenuGroup';
import { AIFloatingContextMenuPopupContent } from './AIFloatingContextMenuPopupContent';
import { PopupStateTypes, isFeaturePopupState } from './popupStateTypes';

const AIButtonSpotlight = LoadableBackground({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-AIButtonSpotlight" */ './AIButtonSpotlight'))
			.AIButtonSpotlight,
});

const absoluteWrapperStyles = xcss({
	display: 'flex',
	position: 'absolute',
	right: 'space.150',
	bottom: 'space.0',
});

const FloatingContextMenuStyles = css({
	position: 'fixed',
	bottom: token('space.600'),
	zIndex: '10',
	transform: 'translateX(-100%)',
});

type AIFloatingContextMenuProps = {
	contentId: string;
	contentType: string;
	spaceKey: string;
	lastModifiedDate?: string | null;
	pageCommentsSectionInView?: boolean;
	isLivePage?: boolean;
	isInObjectSideBar?: boolean;
};

const dynamicActionTextMap: Partial<Record<PopupStateTypes, MessageDescriptor>> = {
	[PopupStateTypes.QuickSummary]: i18nDynamicAction.summarizeSectionLabel,
	[PopupStateTypes.Comments]: i18nDynamicAction.inlineCommentsMenuItemLabel,
	[PopupStateTypes.PageCatchup]: i18nDynamicAction.pageCatchupMenuItemLabel,
	[PopupStateTypes.AIPresentationGeneration]:
		i18nDynamicAction.aiPresentationGenerationMenuItemLabel,
	[PopupStateTypes.BulkIssueCreate]: i18nDynamicAction.bulkIssueCreateMenuItemLabel,
};

const AIFloatingContextMenuWrapped: FC<AIFloatingContextMenuProps> = ({
	contentId,
	contentType,
	spaceKey,
	lastModifiedDate = '',
	pageCommentsSectionInView = false,
	isLivePage = false,
	isInObjectSideBar = false,
}) => {
	const intl = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const { edition, isAdminHubAIEnabled } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [popupState, setPopupState] = useState<PopupStateTypes>(PopupStateTypes.Menu);
	const { dynamicActionTarget, queryError } = useDynamicAIContext(
		contentId,
		contentType,
		pageCommentsSectionInView && !isLivePage,
		isLivePage,
	);
	const { pulseCount } = useAIFloatingButtonNudge(contentId, contentType);
	const { isSiteAdmin } = useContext(SPAViewContext);

	const { isDismissed } = useQuickstartState();
	const { isQuickstartEligible } = useQuickstartEligible();

	const isQuickStartPillShowing = !isDismissed && isQuickstartEligible();

	const { isAIEnabledForContent } = useIsAIEnabledForContent({ contentId });

	const isDynamicActionSelected = isOpen && popupState !== PopupStateTypes.Menu;

	const {
		aiButtonTour: { isVisible, complete: completeSpotlight },
	} = useLiveDocChangeboarding();

	const showSpotlight = isVisible && isAIEnabledForContent && !isQuickStartPillShowing;

	const isEnabled =
		!isFedRamp() &&
		(isAIEnabledForContent ||
			(isSiteAdmin && !isAdminHubAIEnabled) ||
			(edition !== ConfluenceEdition.PREMIUM && !isAdminHubAIEnabled));

	const getDynamicActionText = () => {
		if (dynamicActionTarget && !isOpen) {
			return intl.formatMessage(dynamicActionTextMap[dynamicActionTarget] as MessageDescriptor);
		} else if (isOpen && isFeaturePopupState(popupState)) {
			return intl.formatMessage(dynamicActionTextMap[popupState] as MessageDescriptor);
		} else {
			return undefined;
		}
	};

	const onClose = useCallback(() => {
		setIsOpen(false);
		setPopupState(PopupStateTypes.Menu);
	}, []);

	const onDynamicActionClick = useCallback(() => {
		if (isOpen) {
			onClose();
		} else if (dynamicActionTarget) {
			setIsOpen(true);
			setPopupState(dynamicActionTarget);
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'page',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'aiDynamicActionButton',
				attributes: {
					contentId,
					dynamicActionTarget,
					toggleClose: isOpen,
					isLivePage,
				},
			},
		}).fire();
	}, [contentId, createAnalyticsEvent, isOpen, onClose, dynamicActionTarget, isLivePage]);

	const onMenuButtonClick = useCallback(() => {
		if (isOpen && popupState !== PopupStateTypes.Menu && isAdminHubAIEnabled) {
			setPopupState(PopupStateTypes.Menu);
		} else if (isOpen) {
			onClose();
		} else if (!isAdminHubAIEnabled && edition !== ConfluenceEdition.PREMIUM) {
			setIsOpen(true);
			setPopupState(PopupStateTypes.Upsell);
		} else if (!isAdminHubAIEnabled && isSiteAdmin) {
			setIsOpen(true);
			setPopupState(PopupStateTypes.OptIn);
		} else {
			setIsOpen(true);
		}
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'page',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'aiActionMenuButton',
				attributes: {
					contentId,
					edition,
					toggleClose: isOpen,
					isLivePage,
				},
			},
		}).fire();
	}, [
		contentId,
		createAnalyticsEvent,
		edition,
		isOpen,
		onClose,
		popupState,
		isAdminHubAIEnabled,
		isSiteAdmin,
		isLivePage,
	]);

	const onSpotlightDismissed = useCallback(() => {
		completeSpotlight();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'aiButtonSpotlight',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'dismiss',
				attributes: {
					isLivePage,
				},
			},
		}).fire();
	}, [completeSpotlight, isLivePage, createAnalyticsEvent]);

	const popupComponent = () => {
		if (popupState === PopupStateTypes.Menu) {
			return AIFloatingContextMenuPopupContainer;
		}

		return CustomPopupContainer;
	};

	useEffect(() => {
		if (showSpotlight) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'shown',
					actionSubject: 'spotlight',
					actionSubjectId: 'aiButtonSpotlight',
					source: 'aiButtonSpotlight',
				},
			}).fire();
		}
	}, [showSpotlight, createAnalyticsEvent]);

	if (isQuickStartPillShowing || !isEnabled) {
		return null;
	}

	return (
		<>
			<SpotlightManager>
				{queryError && <ErrorDisplay error={queryError} />}
				<Popup
					isOpen={isOpen}
					onClose={onClose}
					offset={[0, 6]}
					content={() => (
						<AIFloatingContextMenuPopupContent
							popupState={popupState}
							contentId={contentId}
							contentType={contentType}
							spaceKey={spaceKey}
							lastModifiedDate={lastModifiedDate ?? ''}
							source="view-page"
							onClose={onClose}
							setPopupState={setPopupState}
							setIsOpen={setIsOpen}
							isLivePage={isLivePage}
						/>
					)}
					shouldRenderToParent
					placement="top-end"
					popupComponent={popupComponent()}
					trigger={(triggerProps) => (
						<ContextualButtonPulse pulseCount={pulseCount} isInObjectSideBar={isInObjectSideBar}>
							<SpotlightTarget name="ai-floating-button">
								<AIFloatingSplitButton
									isDynamicActionSelected={isDynamicActionSelected}
									triggerProps={triggerProps}
									onMenuButtonClick={onMenuButtonClick}
									onDynamicActionClick={onDynamicActionClick}
									dynamicActionText={getDynamicActionText()}
									isMenuOpen={isOpen && popupState === PopupStateTypes.Menu}
									isInObjectSideBar={isInObjectSideBar}
								/>
							</SpotlightTarget>
						</ContextualButtonPulse>
					)}
				/>
				<SpotlightTransition>
					{showSpotlight && <AIButtonSpotlight onSpotlightDismissed={onSpotlightDismissed} />}
				</SpotlightTransition>
			</SpotlightManager>
		</>
	);
};

export const AIFloatingContextMenu = (props: AIFloatingContextMenuProps) => {
	return (
		<ErrorBoundary attribution={Attribution.SMARTS}>
			<CommentCountsProvider contentId={props.contentId}>
				{props.isInObjectSideBar ? (
					<AIFloatingContextMenuWrapped {...props} />
				) : (
					<Box xcss={absoluteWrapperStyles}>
						<div css={[FloatingContextMenuStyles]}>
							<AIFloatingContextMenuWrapped {...props} />
						</div>
					</Box>
				)}
			</CommentCountsProvider>
		</ErrorBoundary>
	);
};

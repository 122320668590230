import gql from 'graphql-tag';

export const LastModifiedDateQuery = gql`
	query LastModifiedDateQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				metadata {
					lastModifiedDate
				}
			}
		}
	}
`;

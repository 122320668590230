import React, { type FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import { Box, Flex, Inline, xcss, Pressable } from '@atlaskit/primitives';
import type { TriggerProps } from '@atlaskit/popup';
import { ExitingPersistence, FadeIn } from '@atlaskit/motion';
import { token } from '@atlaskit/tokens';

import { AtlassianIntelligenceLogoDark } from '@atlassian/ai-components';

import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';

const i18n = defineMessages({
	logoAltText: {
		id: 'ai-floating-context-menu.floating-context-menu-button.logo-alt-text',
		defaultMessage: 'Atlassian Intelligence',
		description: 'The alt text for the Atlassian Intelligence logo icon',
	},
});

const aiFloatingContextMenuStyles = xcss({
	borderRadius: 'border.radius.300',
	padding: 'space.075',
	boxShadow: 'elevation.shadow.overlay',
	backgroundColor: 'elevation.surface.raised',
});

const aiFloatingContextMenuStylesInObjectSidebar = xcss({
	padding: 'space.100',
	boxShadow: 'elevation.shadow.raised',
	backgroundColor: 'elevation.surface.raised',
	borderRadius: 'border.radius.circle',
});

const dividerStyles = xcss({
	height: '100%',
	width: '1px',
	backgroundColor: 'color.background.neutral',
});

const dividerStylesInObjectSidebar = xcss({
	height: '24px',
	width: '1px',
	backgroundColor: 'color.background.neutral',
});

const defaultPressableStyles = xcss({
	display: 'inline-flex',
	background: token('color.background.neutral.subtle'),
	color: 'color.text.subtle',
	width: 'auto',
	maxWidth: '100%',
	boxSizing: 'border-box',
	position: 'relative',
	flexShrink: 0,
	font: 'font.body',
	fontWeight: 'font.weight.medium',
	paddingBlock: 'space.025',
	paddingInlineEnd: 'space.150',
	paddingInlineStart: 'space.150',
	verticalAlign: 'middle',
	borderRadius: 'border.radius.100',
	transition: 'background 0.1s ease-out',
	whiteSpace: 'nowrap',
	'::after': {
		borderRadius: 'inherit',
		inset: 'space.0',
		borderStyle: 'solid',
		borderWidth: token('border.width'),
		pointerEvents: 'none',
		position: 'absolute',
	},
	':hover': {
		background: token('color.background.neutral.subtle.hovered'),
	},
});

const selectedPressableStyles = xcss({
	background: token('color.background.selected'),
	color: 'color.text.selected',
	'::after': {
		content: '""',
		borderColor: 'color.border.selected',
		borderStyle: 'solid',
	},
	':visited': {
		color: 'color.text.selected',
	},
	':hover': {
		// @ts-expect-error
		color: token('color.text.selected'),
		background: token('color.background.selected.hovered'),
	},
	':active': {
		// @ts-expect-error
		color: token('color.text.selected'),
		background: token('color.background.selected.pressed'),
	},
});

const sideBarPressableStyles = xcss({
	borderRadius: '24px',
	paddingBlock: 'space.075',
});

type AIFloatingContextMenuButtonProps = {
	dynamicActionText?: string;
	isDynamicActionSelected?: boolean;
	onDynamicActionClick?: () => void;
	onMenuButtonClick?: () => void;
	triggerProps?: TriggerProps;
	isMenuOpen?: boolean;
	isInObjectSideBar?: boolean;
};

export const AIFloatingSplitButton: FC<AIFloatingContextMenuButtonProps> = ({
	dynamicActionText,
	isDynamicActionSelected = false,
	onDynamicActionClick,
	onMenuButtonClick,
	triggerProps,
	isMenuOpen,
	isInObjectSideBar = false,
}) => {
	const { formatMessage } = useIntl();
	const renderServerPlaceholder = useRenderServerPlaceholder();

	return (
		<Flex {...triggerProps}>
			<Inline
				xcss={[
					!isInObjectSideBar && aiFloatingContextMenuStyles,
					isInObjectSideBar && aiFloatingContextMenuStylesInObjectSidebar,
				]}
				grow="hug"
				space="space.050"
				alignBlock="center"
			>
				<ExitingPersistence appear={!renderServerPlaceholder} exitThenEnter>
					{dynamicActionText && (
						<>
							<FadeIn entranceDirection="right" exitDirection="right" duration="medium">
								{(props) => (
									<div {...props} aria-modal>
										<Pressable
											onClick={() => onDynamicActionClick?.()}
											xcss={[
												defaultPressableStyles,
												isDynamicActionSelected && selectedPressableStyles,
												isInObjectSideBar && sideBarPressableStyles,
											]}
										>
											{dynamicActionText}
										</Pressable>
									</div>
								)}
							</FadeIn>
							<Box
								xcss={[
									!isInObjectSideBar && dividerStyles,
									isInObjectSideBar && dividerStylesInObjectSidebar,
								]}
							/>
						</>
					)}
				</ExitingPersistence>
				<IconButton
					icon={AtlassianIntelligenceLogoDark}
					label={formatMessage(i18n.logoAltText)}
					onClick={() => onMenuButtonClick?.()}
					appearance="subtle"
					isSelected={isMenuOpen}
					shape={isInObjectSideBar ? 'circle' : 'default'}
				/>
			</Inline>
		</Flex>
	);
};

import { defineMessages } from 'react-intl-next';

import { useObjectSidebar, PanelName } from '@confluence/object-sidebar-api';

import { AudioPanel } from '../components/AudioPanel';
import { useAudioPlaybackState } from '../hooks/useAudioPlaybackState';

const i18n = defineMessages({
	audioPanelTitle: {
		id: 'audio.audio-panel.title',
		defaultMessage: 'Summary Audio',
		description: 'The title for the audio panel',
	},
});

export const useShowAudioPanel = () => {
	const [, { showObjectSidebar }] = useObjectSidebar();

	const [, { onContentStopped }] = useAudioPlaybackState();

	const showAudioPanel = () => {
		showObjectSidebar(
			{
				id: PanelName.AIAudioPanel,
				headerComponentElements: {
					headerLabel: i18n.audioPanelTitle,
				},
				BodyComponent: AudioPanel,
				closeOptions: {
					onPanelClose: onContentStopped,
				},
			},
			'push',
		);
	};

	return { showAudioPanel };
};

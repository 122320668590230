import { useContext } from 'react';
import type { ApolloError } from 'apollo-client';

import { CommentCountsContext } from '@confluence/comment-context';
import { useSessionData } from '@confluence/session-data';
import { useDocumentScrollHeight } from '@confluence/document-scroll-height/entry-points/useDocumentScrollHeight';

import { FeatureStates } from '../popupStateTypes';
import { CatchupContentType } from '../__types__/CatchupLastViewedTimeQuery';

import { useDynamicSplitButton } from './useDynamicSplitButton';

type UseDynamicAIContextReturn = {
	dynamicActionTarget:
		| FeatureStates.Comments
		| FeatureStates.PageCatchup
		| FeatureStates.QuickSummary
		| null;
	queryError?: ApolloError;
};

export const useDynamicAIContext = (
	contentId: string,
	contentType: string,
	pageCommentsSectionInView: boolean,
	isLivePage: boolean,
): UseDynamicAIContextReturn => {
	const { scrollY } = useDocumentScrollHeight(isLivePage);

	const { unresolvedInlineCommentsCount, footerCommentsCount } = useContext(CommentCountsContext);
	const { isAdminHubAIEnabled } = useSessionData();

	const contentTypeEnum =
		contentType === 'page' ? CatchupContentType.PAGE : CatchupContentType.BLOGPOST;
	const { showCatchupAsDefault, queryError } = useDynamicSplitButton(contentId, contentTypeEnum);

	if (!isAdminHubAIEnabled) {
		return { dynamicActionTarget: null };
	}

	if (pageCommentsSectionInView && (unresolvedInlineCommentsCount > 0 || footerCommentsCount > 0))
		return { dynamicActionTarget: FeatureStates.Comments };

	if (showCatchupAsDefault && scrollY !== null && scrollY < 400) {
		return { dynamicActionTarget: FeatureStates.PageCatchup };
	}

	if (scrollY !== null && scrollY < 400) {
		return { dynamicActionTarget: FeatureStates.QuickSummary, queryError };
	}

	return { dynamicActionTarget: null, queryError };
};

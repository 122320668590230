import type { FC } from 'react';
import React, { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';

import AiOptIn from '@atlassian/ai-opt-in';

import {
	CommentsSummarySource,
	FooterCommentsSummaryCombined,
} from '@confluence/ai-comments-summary';
import { PageCatchupComponent } from '@confluence/page-catchup';
import { QuickSummaryStreamingComponent, QuickSummaryUpsell } from '@confluence/quick-summary';
import { BulkIssueCreate } from '@confluence/ai-content-actions';
import { fg } from '@confluence/feature-gating';
import { useSessionData } from '@confluence/session-data';

import { AIFloatingContextMenuGroup } from './AIFloatingContextMenuGroup';
import { PopupStateTypes, isFeaturePopupState } from './popupStateTypes';

export const i18n = defineMessages({
	aiFloatingContextMenuOptInDialogHeaderText: {
		id: 'ai-floating-context-menu.opt-in.dialog.header.text',
		defaultMessage: 'Work smarter, not harder',
		description: 'Header text for Quick Summary AI Opt In Dialog',
	},
	aiFloatingContextMenuAdminOptInDialogBodyText: {
		id: 'ai-floating-context-menu.admin.opt-in.dialog.body.text',
		defaultMessage:
			'Save your team time by reading an Al-generated summary instead of an entire page with <a>Atlassian Intelligence</a>. <br></br><br></br> Activate Atlassian Intelligence features for your users from Atlassian Administration.',
		description: 'Body text for Quick Summary AI Opt In Dialog',
	},
});

const ATLASSIAN_INTELLIGENCE_LEARN_MORE_LINK =
	'https://support.atlassian.com/organization-administration/docs/understand-atlassian-intelligence-features-in-products';

const FeatureContentWidthStyles = xcss({
	width: '500px',
});

type AIFloatingContextMenuPopupContentProps = {
	popupState: PopupStateTypes;
	contentId: string;
	contentType: string;
	spaceKey: string;
	setIsOpen: (state: boolean) => void;
	setPopupState: (popupKey: PopupStateTypes) => void;
	lastModifiedDate: string;
	source: string;
	onClose: () => void;
	isLivePage?: boolean;
};

export const AIFloatingContextMenuPopupContent: FC<AIFloatingContextMenuPopupContentProps> = memo(
	({
		popupState,
		contentId,
		contentType,
		spaceKey,
		lastModifiedDate,
		source,
		onClose,
		setIsOpen,
		setPopupState,
		isLivePage = false,
	}) => {
		const intl = useIntl();
		const { orgId } = useSessionData();
		const adminLink = `https://admin.atlassian.com/o/${orgId}/atlassian-intelligence`;

		const formatOptInMessageValues = {
			a: (s: React.ReactNode[]) => (
				<a href={ATLASSIAN_INTELLIGENCE_LEARN_MORE_LINK} target="_blank">
					{s}
				</a>
			),
			br: () => <br />,
		};

		const renderContent = () => {
			switch (popupState) {
				case PopupStateTypes.Comments:
					return (
						<FooterCommentsSummaryCombined
							contentId={contentId}
							contentType={contentType}
							source={CommentsSummarySource.Header}
							spaceKey={spaceKey}
						/>
					);

				case PopupStateTypes.PageCatchup:
					return (
						<PageCatchupComponent
							contentId={contentId}
							contentType={contentType}
							spaceKey={spaceKey}
						/>
					);
				case PopupStateTypes.QuickSummary:
					return (
						<QuickSummaryStreamingComponent
							contentId={contentId}
							spaceKey={spaceKey}
							contentType={contentType}
							lastModifiedDate={lastModifiedDate}
							setIsOpen={onClose}
							source={source}
							contentPath={window.location.pathname}
						/>
					);
				case PopupStateTypes.BulkIssueCreate:
					return fg('confluence_ai_bulk_issue_creation') ? null : (
						<BulkIssueCreate contentId={contentId} />
					);
				case PopupStateTypes.Upsell:
					return <QuickSummaryUpsell />;

				case PopupStateTypes.OptIn:
					return (
						<AiOptIn
							source="quickSummary"
							product="confluence"
							isOrgAdmin
							headerText={intl.formatMessage(i18n.aiFloatingContextMenuOptInDialogHeaderText)}
							adminBodyText={intl.formatMessage(
								i18n.aiFloatingContextMenuAdminOptInDialogBodyText,
								formatOptInMessageValues,
							)}
							adminLink={adminLink}
							onAdminClose={() => setIsOpen(false)}
						/>
					);
				default:
					return (
						<AIFloatingContextMenuGroup
							contentId={contentId}
							contentType={contentType}
							spaceKey={spaceKey}
							setPopupState={setPopupState}
							setIsOpen={setIsOpen}
							isLivePage={isLivePage}
						/>
					);
			}
		};

		return (
			<Box xcss={[isFeaturePopupState(popupState) && FeatureContentWidthStyles]}>
				{renderContent()}
			</Box>
		);
	},
);

import type { FC } from 'react';
import React, { useState, useEffect } from 'react';
import { styled } from '@compiled/react';

import { Box, Flex, Text, xcss } from '@atlaskit/primitives';

const BORDER_RADIUS = 'border.radius.circle';
const THUMB_SIZE = '12px';
const TRACK_HEIGHT = '4px';
const EXPANDED_HEIGHT = '8px';
const TRANSITION_DURATION = '0.2s';

const expandContainerStyles = xcss({
	position: 'relative',
	height: EXPANDED_HEIGHT,
});

const progressBarWrapperStyles = xcss({
	position: 'relative',
	width: '100%',
	height: TRACK_HEIGHT,
	borderRadius: BORDER_RADIUS,
	backgroundColor: 'color.background.accent.gray.subtlest',
	transition: `${TRANSITION_DURATION} ease`,
	':hover': {
		height: EXPANDED_HEIGHT,
	},
});

const playedBarStyles = xcss({
	height: '100%',
	borderRadius: BORDER_RADIUS,
	position: 'absolute',
	pointerEvents: 'none',
});

const thumbStyles = xcss({
	position: 'absolute',
	width: '0px',
	height: '0px',
	borderRadius: 'border.radius.circle',
	backgroundColor: 'color.background.brand.bold',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	transition: `width ${TRANSITION_DURATION} ease, height ${TRANSITION_DURATION} ease`,
	pointerEvents: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const RangeInput = styled.input({
	position: 'absolute',
	width: '100%',
	height: '100%',
	opacity: 0,
	cursor: 'pointer',
});

type AudioProgressBarProps = {
	progress: number;
	currentTime: number;
	totalTime: number;
	onProgressChange: (progress: number) => void;
};

const formatTime = (timeInSeconds: number): string => {
	const minutes = Math.floor(timeInSeconds / 60);
	const seconds = Math.floor(timeInSeconds % 60);
	return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

export const AudioProgressBar: FC<AudioProgressBarProps> = ({
	progress,
	currentTime,
	totalTime,
	onProgressChange,
}) => {
	const [isDragging, setIsDragging] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	const [dragValue, setDragValue] = useState(progress);

	useEffect(() => {
		if (!isDragging) {
			setDragValue(progress);
		}
	}, [progress, isDragging]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDragValue(parseFloat(e.target.value));
	};

	const handleMouseDown = () => {
		setIsDragging(true);
	};

	const handleMouseUp = () => {
		if (isDragging) {
			onProgressChange(dragValue);
			setIsDragging(false);
		}
	};

	const handleMouseEnter = () => {
		setIsHovering(true);
	};

	const handleMouseLeave = () => {
		setIsHovering(false);
		if (isDragging) {
			handleMouseUp();
		}
	};

	const currentProgress = isDragging ? dragValue : progress;
	const progressWidth = currentProgress * 100;

	return (
		<Box paddingInline="space.200">
			<Flex alignItems="center" xcss={expandContainerStyles}>
				<Box
					xcss={progressBarWrapperStyles}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					testId="progress-bar-wrapper"
				>
					<Box
						testId="current-progress-bar"
						backgroundColor="color.background.brand.bold"
						xcss={playedBarStyles}
						style={{ width: `${progressWidth}%` }}
					/>
					<Box
						xcss={thumbStyles}
						style={{
							left: `${progressWidth}%`,
							width: isDragging || isHovering ? THUMB_SIZE : '0px',
							height: isDragging || isHovering ? THUMB_SIZE : '0px',
						}}
						testId="progress-bar-thumb"
					/>
					<RangeInput
						type="range"
						min="0"
						max="1"
						step="0.001"
						value={currentProgress}
						onChange={handleChange}
						onMouseDown={handleMouseDown}
						onMouseUp={handleMouseUp}
					/>
				</Box>
			</Flex>
			<Flex justifyContent="space-between">
				<Text size="small" color="color.text.subtle" weight="semibold">
					{formatTime(currentTime)}
				</Text>
				<Text size="small" color="color.text.subtle" weight="semibold">
					{formatTime(totalTime)}
				</Text>
			</Flex>
		</Box>
	);
};

import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { useIsNav4Enabled } from '@confluence/nav4-enabled';

export type UseDocumentScrollHeightReturn = {
	scrollY: number | null;
};

export const useDocumentScrollHeight = (isLivePage = false): UseDocumentScrollHeightReturn => {
	const isNav4Enabled = useIsNav4Enabled();
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		return { scrollY: 0 };
	} else {
		const newViewPageContainer = document.getElementById('AkMainContent');
		const [scrollHeightAttributes, setScrollHeightAttributes] =
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useState<UseDocumentScrollHeightReturn>({
				scrollY: 0,
			});

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const handleScroll = useCallback(() => {
			const livePageContainer = document.querySelector('.fabric-editor-popup-scroll-parent');
			const viewPageScrollY =
				newViewPageContainer && isNav4Enabled ? newViewPageContainer.scrollTop : window.scrollY;

			setScrollHeightAttributes({
				scrollY: isLivePage ? livePageContainer && livePageContainer.scrollTop : viewPageScrollY,
			});
		}, [setScrollHeightAttributes, isLivePage, newViewPageContainer, isNav4Enabled]);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			const livePageContainer = document.querySelector('.fabric-editor-popup-scroll-parent');
			const debounceScroll = debounce(handleScroll, 250);

			if (isLivePage) {
				livePageContainer?.addEventListener('scroll', debounceScroll);
			} else {
				isNav4Enabled
					? newViewPageContainer?.addEventListener('scroll', debounceScroll)
					: document.addEventListener('scroll', debounceScroll);
			}

			return () => {
				if (isLivePage) {
					livePageContainer?.removeEventListener('scroll', debounceScroll);
				} else {
					isNav4Enabled
						? newViewPageContainer?.removeEventListener('scroll', debounceScroll)
						: document.removeEventListener('scroll', debounceScroll);
				}
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return scrollHeightAttributes;
	}
};

import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Stack, Box, Inline } from '@atlaskit/primitives';
import Image from '@atlaskit/image';
import { cssMap } from '@atlaskit/css';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';

import { ErrorDisplay } from '@confluence/error-boundary';

import AudioPanelErrorIllustration from '../assets/AudioPanelErrorIllustration.svg';
import AudioPanelErrorIllustrationDark from '../assets/AudioPanelErrorIllustrationDark.svg';

const styles = cssMap({
	container: {
		paddingBlock: token('space.150'),
		paddingInline: token('space.300'),
	},
	errorIllustrationContainer: {
		width: '100px',
		height: '100px',
	},
	errorTextContainer: {
		textAlign: 'center',
		whiteSpace: 'pre-line',
	},
});

const i18n = defineMessages({
	errorMessage: {
		id: 'audio.panel.error.message',
		defaultMessage: 'The audio for this summary couldn’t load. \n Please try again.',
		description: 'Error message for the audio panel',
	},
	errorImgAlt: {
		id: 'audio.panel.error.img.alt',
		defaultMessage: 'Audio panel error illustration',
		description: 'Alt text for the audio panel error illustration',
	},
});

type AudioPanelErrorComponentProps = {
	error: Error;
};

export const AudioPanelErrorComponent: FC<AudioPanelErrorComponentProps> = ({ error }) => {
	const { formatMessage } = useIntl();

	return (
		<Stack alignInline="center" xcss={styles.container} space="space.200">
			<Inline xcss={styles.errorIllustrationContainer} alignInline="center" alignBlock="center">
				<Image
					src={AudioPanelErrorIllustration}
					alt={formatMessage(i18n.errorImgAlt)}
					srcDark={AudioPanelErrorIllustrationDark}
				/>
			</Inline>
			<Heading size="xsmall">
				<Box xcss={styles.errorTextContainer}>{formatMessage(i18n.errorMessage)}</Box>
			</Heading>
			<ErrorDisplay error={error} />
		</Stack>
	);
};

import type { FC, ReactNode } from 'react';
import React, { useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Pressable, xcss } from '@atlaskit/primitives';
import { IconTile } from '@atlaskit/icon';
import VisuallyHidden from '@atlaskit/visually-hidden';
import Tooltip from '@atlaskit/tooltip';
import VideoPauseOverlayIcon from '@atlaskit/icon/core/video-pause-overlay';
import VideoPlayOverlayIcon from '@atlaskit/icon/core/video-play-overlay';
import Spinner from '@atlaskit/spinner';

import { useAudioPlaybackState } from '../hooks/useAudioPlaybackState';

const i18n = defineMessages({
	pauseButtonLabel: {
		id: 'audio.play-pause-button.pause-label',
		defaultMessage: 'Pause',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will pause the actively playing audio',
	},
	resumeButtonLabel: {
		id: 'audio.play-pause-button.resume-label',
		defaultMessage: 'Resume',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will resume the actively paused audio',
	},
	loadingButtonLabel: {
		id: 'audio.play-pause-button.loading-label',
		defaultMessage: 'Loading',
		description:
			'The label is used as a tooltip and for accessibility for the icon button when the audio is loading',
	},
});

export type PlayPauseButtonProps = {
	onPause: () => void;
	onResume: () => Promise<void>;
};

// These styles "extend" the IconTile to give the appearance of more padding around the icon
const buttonStyles = xcss({
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.brand.bold',
	borderRadius: 'border.radius.circle',
	width: '2.75rem',
	height: '2.75rem',
});

export const PlayPauseButton: FC<PlayPauseButtonProps> = ({ onPause, onResume }) => {
	const { formatMessage } = useIntl();
	const pressableRef = useRef<HTMLButtonElement>(null);

	const [{ isLoading, isPlaying }] = useAudioPlaybackState();

	useEffect(() => {
		if (pressableRef.current) {
			pressableRef.current.focus();
		}
	}, []);

	let labelValue: string;
	let buttonIcon: ReactNode;

	if (isLoading) {
		labelValue = formatMessage(i18n.loadingButtonLabel);
		buttonIcon = <Spinner appearance="invert" label={labelValue} testId="miniplayer-loading" />;
	} else if (isPlaying) {
		labelValue = formatMessage(i18n.pauseButtonLabel);
		buttonIcon = (
			<IconTile
				icon={VideoPauseOverlayIcon}
				appearance="blueBold"
				shape="circle"
				size="32"
				label={labelValue}
				testId="miniplayer-pause"
			/>
		);
	} else {
		labelValue = formatMessage(i18n.resumeButtonLabel);
		buttonIcon = (
			<IconTile
				icon={VideoPlayOverlayIcon}
				appearance="blueBold"
				shape="circle"
				size="32"
				label={labelValue}
				testId="miniplayer-resume"
			/>
		);
	}

	return (
		<Tooltip content={labelValue}>
			<Pressable
				xcss={buttonStyles}
				onClick={isPlaying ? onPause : onResume}
				componentName="PlayPauseButton"
				ref={pressableRef}
				isDisabled={isLoading}
			>
				{buttonIcon}
				<VisuallyHidden>{labelValue}</VisuallyHidden>
			</Pressable>
		</Tooltip>
	);
};

import type { FC } from 'react';
import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import SpeedometerIcon from '@atlaskit/icon-lab/core/speedometer';
import Button from '@atlaskit/button/new';

import { useAudioPlaybackState } from '../hooks/useAudioPlaybackState';

const i18n = defineMessages({
	playbackSpeedLabel: {
		id: 'audio.miniplayer-playback-speed-control.playback-speed-label',
		defaultMessage: 'Playback Speed',
		description:
			'The label is used as a tooltip and for accessibility for the select dropdown which provides playback speed options for the actively playing audio',
	},
	speedLabel08x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-08x',
		defaultMessage: '0.8x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 0.8x',
	},
	speedLabel10x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-10x',
		defaultMessage: '1.0x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 1.0x',
	},
	speedLabel12x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-12x',
		defaultMessage: '1.2x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 1.2x',
	},
	speedLabel15x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-15x',
		defaultMessage: '1.5x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 1.5x',
	},
	speedLabel17x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-17x',
		defaultMessage: '1.7x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 1.7x',
	},
	speedLabel20x: {
		id: 'audio.miniplayer-playback-speed-control.speed-label-20x',
		defaultMessage: '2.0x',
		description:
			'The label is is shown on the select option in the audio miniplayer controls for audio playback speed of 2.0x',
	},
});

export const MiniplayerPlaybackSpeedControl: FC = () => {
	const intl = useIntl();

	const [{ playbackSpeed }, { onContentPlaybackSpeedChanged }] = useAudioPlaybackState();

	const PLAYBACK_RATES = useMemo(
		() => [
			{ label: intl.formatMessage(i18n.speedLabel08x), value: 0.8 },
			{ label: intl.formatMessage(i18n.speedLabel10x), value: 1.0 },
			{ label: intl.formatMessage(i18n.speedLabel12x), value: 1.2 },
			{ label: intl.formatMessage(i18n.speedLabel15x), value: 1.5 },
			{ label: intl.formatMessage(i18n.speedLabel17x), value: 1.7 },
			{ label: intl.formatMessage(i18n.speedLabel20x), value: 2.0 },
		],
		[intl],
	);

	const getLabel = (speed: number) => PLAYBACK_RATES.find((item) => item.value === speed)?.label;

	return (
		<DropdownMenu
			placement="bottom-start"
			trigger={({ triggerRef, ...triggerProps }) => (
				<Button
					{...triggerProps}
					ref={triggerRef}
					iconBefore={SpeedometerIcon}
					appearance="subtle"
					spacing="compact"
				>
					<Text>{getLabel(playbackSpeed)}</Text>
				</Button>
			)}
		>
			<DropdownItemGroup>
				{PLAYBACK_RATES.map(({ label, value }) => (
					<DropdownItem key={value} onClick={() => onContentPlaybackSpeedChanged(value)}>
						{label}
					</DropdownItem>
				))}
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

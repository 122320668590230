import { createStore, createHook, createContainer } from 'react-sweet-state';

import {
	confluenceLocalStorageInstance as localStorage,
	keys as localStorageKeys,
} from '@confluence/storage-manager';

import { getSpeech } from '../api/speechAPI';
import type { AudioRequest } from '../types/audioRequestType';
import type { ActiveAudioContent } from '../types/activeAudioContent';

export interface AudioPlaybackState {
	activeContent?: ActiveAudioContent;
	isLoading: boolean;
	isPlaying: boolean;
	isClosed: boolean;
	isEnded: boolean;
	playbackSpeed: number;
	error?: Error;
	stream?: ReadableStream<Uint8Array>;
}

type AudioPlaybackStateContainerProps = {
	initialAudioPlaybackState?: Partial<AudioPlaybackState>;
};

export const audioPlaybackActions = {
	initialize:
		(initialState: Partial<AudioPlaybackState> = {}) =>
		async ({ setState }) => {
			setState(
				Object.assign(
					{
						stream: undefined,
						activeContent: undefined,
						isLoading: false,
						isPlaying: false,
						isClosed: false,
						isEnded: false,
						error: undefined,
						playbackSpeed:
							Number(localStorage.getItem(localStorageKeys.AI_AUDIO_PLAYBACK_SPEED)) || 1,
					},
					initialState,
				),
			);
		},
	updateActiveContent:
		(activeContent: Partial<ActiveAudioContent>) =>
		({ setState, getState }) => {
			const { activeContent: activeContentFromState } = getState();
			if (activeContent) {
				setState({
					activeContent: {
						...activeContentFromState,
						...activeContent,
					},
				});
			}
		},
	onLoadStart:
		(activeContent: ActiveAudioContent) =>
		({ setState, getState }) => {
			const { activeContent: activeContentFromState } = getState();
			setState({
				activeContent: {
					...activeContentFromState,
					...activeContent,
				},
				isLoading: true,
			});
		},
	onLoadEnd:
		() =>
		({ setState }) => {
			setState({ isLoading: false });
		},
	onPlayContent:
		(audioRequest: AudioRequest, activeContent: ActiveAudioContent) =>
		async ({ getState, setState, dispatch }) => {
			await dispatch(audioPlaybackActions.initialize());
			dispatch(audioPlaybackActions.onLoadStart(activeContent));

			try {
				const stream = await getSpeech(audioRequest);

				if (getState().isClosed) {
					return;
				}

				const { activeContent: activeContentFromState } = getState();
				setState({
					activeContent: {
						...activeContentFromState,
						...activeContent,
					},
					isPlaying: true,
					isEnded: false,
					stream,
				});

				return stream;
			} catch (e) {
				setState({
					error: e,
					activeContent: undefined,
					stream: undefined,
				});
				throw e;
			} finally {
				dispatch(audioPlaybackActions.onLoadEnd());
			}
		},
	onContentPaused:
		() =>
		({ setState }) => {
			setState({
				isPlaying: false,
			});
		},
	onContentResumed:
		() =>
		({ setState }) => {
			setState({
				isPlaying: true,
				isEnded: false,
			});
		},
	onContentPlaybackSpeedChanged:
		(speed: number) =>
		({ setState }) => {
			localStorage.setItem(localStorageKeys.AI_AUDIO_PLAYBACK_SPEED, speed);
			setState({ playbackSpeed: speed });
		},
	onContentStopped:
		() =>
		async ({ dispatch, setState }) => {
			await dispatch(audioPlaybackActions.initialize());
			setState({ isClosed: true });
		},
	onContentEnded:
		() =>
		({ setState }) => {
			setState({
				isEnded: true,
				isPlaying: false,
			});
		},
	onContentError:
		(error: Error) =>
		({ setState }) => {
			setState({
				error,
			});
		},
};

export type AudioPlaybackActions = typeof audioPlaybackActions;

const Store = createStore<AudioPlaybackState, any>({
	initialState: {
		activeContent: undefined,
		isLoading: false,
		isPlaying: false,
		isClosed: false,
		isEnded: false,
		playbackSpeed: 1,
		error: undefined,
	},
	actions: audioPlaybackActions,
	name: 'listenerState',
});

export const AudioPlaybackStateContainer = createContainer<
	AudioPlaybackState,
	AudioPlaybackActions,
	AudioPlaybackStateContainerProps
>(Store, {
	onInit:
		() =>
		async ({ dispatch }, { initialAudioPlaybackState }) => {
			void dispatch(audioPlaybackActions.initialize(initialAudioPlaybackState));
		},
});

export const useAudioPlaybackState = createHook<AudioPlaybackState, AudioPlaybackActions>(Store);

import type { FC } from 'react';
import React from 'react';

import { Box, Stack, Inline, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ConfluenceIcon } from '@atlaskit/logo';
import { ConfluenceIcon as NewConfluenceIcon } from '@atlaskit/temp-nav-app-icons/confluence';

import { fg } from '@confluence/feature-gating';
import { ContentIcon } from '@confluence/icons/entry-points/ContentIcon';

const bylineStyles = xcss({
	color: 'color.text.subtle',
});

type TrackCardProps = {
	contentType?: string | null;
	contentSubType?: string | null;
	contentTitle?: string | null;
	spaceName?: string | null;
	product: string;
};

export const TrackCard: FC<TrackCardProps> = ({
	contentType,
	contentSubType,
	contentTitle,
	spaceName,
	product,
}) => {
	const ConfluenceLogo = fg('platform-team25-app-icon-tiles') ? (
		<NewConfluenceIcon size="20" />
	) : (
		<ConfluenceIcon appearance="brand" size="small" />
	);

	return (
		<Box paddingInline="space.200" paddingBlock="space.150" testId="track-card">
			<Box>
				<Inline space="space.200" alignBlock="center">
					{contentType && (
						<ContentIcon
							type={contentType}
							subType={contentSubType}
							color={token('color.icon.brand')}
						/>
					)}
					<Stack>
						<Box>
							<Text color="color.text" maxLines={1} weight="medium">
								{contentTitle}
							</Text>
						</Box>
						<Inline xcss={bylineStyles} space="space.050" alignBlock="center">
							<Box>{spaceName}</Box>
							<Box>•</Box>
							<Inline space="space.050" alignBlock="center">
								{ConfluenceLogo}
								<Box>{product}</Box>
							</Inline>
						</Inline>
					</Stack>
				</Inline>
			</Box>
		</Box>
	);
};

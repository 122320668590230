export enum FeatureStates {
	QuickSummary = 'quick-summary',
	Comments = 'comments',
	PageCatchup = 'page-catchup',
	AIPresentationGeneration = 'ai-presentation-generation',
	BulkIssueCreate = 'bulk-issue-create',
	QuickSummaryAudio = 'quick-summary-audio',
}

export enum NonFeatureStates {
	Menu = 'menu',
	OptIn = 'opt-in',
	Upsell = 'upsell',
}

export const PopupStateTypes = { ...FeatureStates, ...NonFeatureStates };
export type PopupStateTypes = FeatureStates | NonFeatureStates;

export function isFeaturePopupState(popupState: PopupStateTypes): boolean {
	return Object.values(FeatureStates).includes(popupState as FeatureStates);
}

import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { SPAViewContext } from '@confluence/spa-view-context';
import { AIUpsell } from '@confluence/change-edition/entry-points/AIUpsell';
import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';

import quickSummaryUpsellBannerLight from './assets/quickSummaryUpsellBannerLight.svg';
import quickSummaryUpsellBannerDark from './assets/quickSummaryUpsellBannerDark.svg';

const i18nBase = defineMessages({
	headerText: {
		id: 'quick-summary.upsell.header.text',
		defaultMessage: 'Help your organization work smarter',
		description: 'Header text for Quick Summary Upsell Dialog',
	},
	learnMoreLinkTextAdmin: {
		id: 'quick-summary.upsell.learn-more.link.text.admin',
		defaultMessage: 'More about Atlassian Intelligence',
		description: 'Short version of the learn more link text for AI Upsell Dialog',
	},
	learnMoreLinkTextUser: {
		id: 'quick-summary.upsell.learn-more.link.text.user',
		defaultMessage: 'Learn more about Atlassian Intelligence',
		description: 'Learn more link text for AI Upsell Dialog',
	},
	bodyTextAdmin: {
		id: 'quick-summary.upsell.body.text.admin',
		defaultMessage:
			'Save over 45 minutes per week with Atlassian Intelligence features when you upgrade to the Premium plan.',
		description: 'Body text for Quick Summary Upsell Dialog for admins',
	},
	bodyTextUser: {
		id: 'quick-summary.upsell.body.text.user',
		defaultMessage:
			'With the premium plan, you could be saving over 45 minutes per week with Atlassian Intelligence features.',
		description: 'Body text for Quick Summary Upsell Dialog for non-admin users',
	},
});

const i18nLiveDocs = defineMessages({
	bodyTextAdmin: {
		id: 'quick-summary.upsell.body.text.admin.livedocs',
		defaultMessage:
			'Save time and generate summaries for pages, live docs, or blogs with Atlassian Intelligence. Upgrade to Premium to give it a try.',
		description: 'Updated version of quick-summary.upsell.body.text.admin',
	},

	bodyTextUser: {
		id: 'quick-summary.upsell.body.text.user.livedocs',
		defaultMessage:
			'With the Premium plan, you could be saving time by summarizing pages, live docs, and blogs using Atlassian Intelligence.',
		description: 'Updated version of quick-summary.upsell.body.text.user',
	},
});

const i18n = mergeLiveDocI18n(i18nBase, i18nLiveDocs);

export const ATLASSIAN_INTELLIGENCE_DOCS_LINK =
	'https://support.atlassian.com/organization-administration/docs/understand-atlassian-intelligence-features-in-products/';

export const QuickSummaryUpsell = () => {
	const intl = useIntl();
	const { isSiteAdmin } = useContext(SPAViewContext);

	const headerText = intl.formatMessage(i18n.headerText);
	const bodyText = isSiteAdmin
		? intl.formatMessage(i18n.bodyTextAdmin)
		: intl.formatMessage(i18n.bodyTextUser);

	const learnMoreText = isSiteAdmin
		? intl.formatMessage(i18n.learnMoreLinkTextAdmin)
		: intl.formatMessage(i18n.learnMoreLinkTextUser);

	return (
		<AIUpsell
			title={headerText}
			body={bodyText}
			feature="quickSummary"
			learnMoreLink={{
				href: ATLASSIAN_INTELLIGENCE_DOCS_LINK,
				message: learnMoreText,
			}}
			image={{
				src: {
					default: quickSummaryUpsellBannerLight,
					dark: quickSummaryUpsellBannerDark,
				},
				alt: '',
			}}
		/>
	);
};

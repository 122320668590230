import { useContext } from 'react';

import { SPAViewContext } from '@confluence/spa-view-context';
import { useSessionData, useIsLicensed } from '@confluence/session-data';
import { useMigratedUser } from '@confluence/onboarding-helpers/entry-points/hooks/useMigratedUser';
import { useWindowSize } from '@confluence/dom-helpers';
import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import {
	ONBOARDING_EXPERIENCES,
	ORIGIN_ATTRIBUTES,
} from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useRouteName } from '@confluence/route-manager';
import { fg } from '@confluence/feature-gating';
import { useObjectSidebarState } from '@confluence/object-sidebar-api';
import { expValEqualsNoExposure } from '@confluence/feature-experiments';

import { QS_WINDOW_WIDTH_THRESHOLD } from './constants';
import { isNameAllowed } from './isNameAllowed';

export const useQuickstartEligible = () => {
	const { userId } = useSessionData();
	const isLicensed = useIsLicensed();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { isMigratedUser, isMigratedUserReady } = useMigratedUser({
		skip: !fg('cc_onboarding_experience'),
	});
	const { isObjectSidebarShown } = useObjectSidebarState();

	const { data } = useGetOnboardingState([
		ORIGIN_ATTRIBUTES,
		ONBOARDING_EXPERIENCES.IS_FOUNDING_ADMIN,
	]);
	const state = deserializeState(data);

	const checkAllowedRoutes = {
		selector: (name) => isNameAllowed(name),
	};

	const isAllowlistPath = useRouteName(checkAllowedRoutes);
	const { width: windowWidth } = useWindowSize();

	const isWidthThresholdMet = windowWidth >= QS_WINDOW_WIDTH_THRESHOLD;

	const isAnonymousUser = !userId || !isLicensed;
	const isFoundingAdmin = state?.isFoundingAdmin;
	const isTarget = isAllowlistPath && !isAnonymousUser && isWidthThresholdMet;

	// -------- JSW XFlow Quickstart Targeting
	let isJSWXFlowUser: boolean;
	const attributes = state?.attributes ?? '{}';

	try {
		isJSWXFlowUser = JSON.parse(attributes)?.originProduct === 'jira';
	} catch (e) {
		if (process.env.NODE_ENV !== 'production') {
			// eslint-disable-next-line
			console.error('Failed to parse JSON object from state.attributes', attributes);
		}
		isJSWXFlowUser = false;
	}

	const isNthUserQSV2Eligible = isTarget && !isFoundingAdmin && fg('cc_onboarding_experience');

	const isAdminQuickStartV2Eligible = isTarget && isFoundingAdmin && fg('cc_onboarding_experience');

	const isMigrationsQuickstartEligible =
		isTarget &&
		!isSiteAdmin &&
		isMigratedUserReady &&
		isMigratedUser &&
		fg('cc_onboarding_experience');

	const isJswXflowUserQSEligible = isJSWXFlowUser && isTarget && fg('cc_onboarding_experience');

	const isObjectSidebarCurrentlyShowing =
		isObjectSidebarShown && fg('confluence_frontend_object_sidebar');

	const isQuickstartEligible = () => {
		return (
			isTarget && // Allow list paths
			!isFoundingAdmin &&
			// Home Takeover experiment is to replace Quickstart
			!expValEqualsNoExposure('cc_onboarding_nth_user_home_takeover', 'cohort', 'experiment') &&
			// core QS experiment FFs
			(isJswXflowUserQSEligible ||
				isAdminQuickStartV2Eligible ||
				isMigrationsQuickstartEligible ||
				isNthUserQSV2Eligible) &&
			!isObjectSidebarCurrentlyShowing &&
			fg('cc_onboarding_experience') // Master FF
		);
	};

	return {
		isTarget,
		isSiteAdmin,
		isOnboardingEligible: fg('cc_onboarding_experience'),
		isQuickstartEligible,
		isNthUserQSV2Eligible,
		isAdminQuickStartV2Eligible,
		isMigrationsQuickstartEligible,
		isJswXflowUserQSEligible,
	};
};

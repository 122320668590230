import type { FC } from 'react';
import React, { useEffect } from 'react';
import { styled } from '@compiled/react';

import { token, useThemeObserver } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { Card } from '../UpsellLooknFeel/Card';
import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';
import type { LinkProps } from '../SectionMessage/ChangeEditionSectionMessageButtonsWrapper';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UpsellBackgroundBorder = styled.div({
	overflow: 'hidden',
	boxShadow: `${token('elevation.shadow.overlay')}`,

	borderRadius: '5px',

	width: '400px',
	padding: `${token('space.025')}`,
	position: 'relative',

	background: `conic-gradient(
      from 270deg,
      #1d7afc 10%,
      #8bdbe5 30%,
      #f8e6a0 50%,
      #8bdbe5 70%,
      #1d7afc 90%
    )`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UpsellContainer = styled.div({
	borderRadius: '3px',
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: token('elevation.surface.overlay'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageWrapper = styled.img({
	borderRadius: '3px 3px 0 0',
	height: '80px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		borderRadius: '0 0 3px 3px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> div': {
			paddingTop: token('space.200'),
		},
	},
});

export type AIOptInUpsellLinkProps = Omit<LinkProps, 'actionSubjectId' | 'messageDescriptor'> & {
	message: string;
};

type ImageSrcProps = {
	default: string;
	dark: string;
};

type ImageProps = {
	src: ImageSrcProps;
	alt: string;
};

type AIUpsellProps = {
	title: string;
	body: string;
	feature: string;
	image?: ImageProps;
	learnMoreLink: AIOptInUpsellLinkProps;
};

export const AIUpsell: FC<AIUpsellProps> = ({ title, body, feature, image, learnMoreLink }) => {
	const { colorMode } = useThemeObserver();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const bannerImage = colorMode === 'dark' ? image?.src.dark : image?.src.default;

	useEffect(() => {
		if (bannerImage) {
			const preloadImages = (paths: string[]) => {
				paths.forEach((path) => {
					const img = new Image();
					img.src = path;
				});
			};
			preloadImages([bannerImage]);
		}
	}, [bannerImage]);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'aiUpsellDialog',
				attributes: {
					feature,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, feature]);

	return (
		<UpsellBackgroundBorder>
			<UpsellContainer>
				<ContentContainer>
					<Card
						title={title}
						body={body}
						learnMoreLink={{
							...learnMoreLink,
							messageDescriptor: {
								id: 'change-edition.ai-upsell.learn-more',
								defaultMessage: learnMoreLink.message,
							},
							actionSubjectId: `${feature}LearnMore`,
						}}
						source={`${feature}UpsellScreen`}
						targetEdition={ConfluenceEdition.PREMIUM}
						upgradeEditionActionSubjectId={`${feature}TryPremium`}
					>
						<ImageWrapper src={bannerImage} />
					</Card>
				</ContentContainer>
			</UpsellContainer>
		</UpsellBackgroundBorder>
	);
};

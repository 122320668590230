import { useEffect, useRef, useState } from 'react';

const AUDIO_TYPE = 'audio/mpeg';

const setupMediaSource = async (
	audio: HTMLAudioElement,
): Promise<{
	mediaSource: MediaSource;
	sourceBuffer: SourceBuffer;
}> => {
	const mediaSource = new MediaSource();

	await new Promise<void>((resolve, reject) => {
		const handleSourceOpen = () => {
			mediaSource.removeEventListener('sourceopen', handleSourceOpen);
			mediaSource.removeEventListener('error', handleError);
			resolve();
		};

		const handleError = () => {
			mediaSource.removeEventListener('sourceopen', handleSourceOpen);
			mediaSource.removeEventListener('error', handleError);
			reject(new Error('MediaSource error'));
		};

		mediaSource.addEventListener('sourceopen', handleSourceOpen);
		mediaSource.addEventListener('error', handleError);
		audio.src = URL.createObjectURL(mediaSource);
		audio.load();
	});

	const sourceBuffer = mediaSource.addSourceBuffer(AUDIO_TYPE);
	sourceBuffer.mode = 'sequence';

	return { mediaSource, sourceBuffer };
};

const appendChunk = async (
	sourceBuffer: SourceBuffer,
	chunk: Uint8Array,
	isFirstChunk: boolean,
	audio: HTMLAudioElement | null,
	onError: (error: Error) => void,
) => {
	return new Promise<void>((resolve, reject) => {
		if (sourceBuffer.updating) {
			sourceBuffer.addEventListener(
				'updateend',
				() => {
					appendChunk(sourceBuffer, chunk, isFirstChunk, audio, onError)
						.then(resolve)
						.catch(reject);
				},
				{ once: true },
			);
			return;
		}

		sourceBuffer.appendBuffer(chunk);
		sourceBuffer.addEventListener(
			'updateend',
			() => {
				if (isFirstChunk && audio) {
					isFirstChunk = false;
					audio.play().catch(onError);
				}
				resolve();
			},
			{ once: true },
		);
	});
};

export const useAudioStream = (
	stream: ReadableStream<Uint8Array> | undefined,
	onError: (error: Error) => void,
) => {
	const audioRef = useRef<HTMLAudioElement>(null);
	const mediaSourceRef = useRef<MediaSource | null>(null);
	const sourceBufferRef = useRef<SourceBuffer | null>(null);
	const [isMediaSourceReady, setIsMediaSourceReady] = useState(false);

	// Setup MediaSource
	useEffect(() => {
		const setupAudio = async () => {
			const audio = audioRef.current;
			if (!audio) return;

			try {
				const { mediaSource, sourceBuffer } = await setupMediaSource(audio);
				mediaSourceRef.current = mediaSource;
				sourceBufferRef.current = sourceBuffer;
				setIsMediaSourceReady(true);
			} catch (e) {
				onError(e as Error);
			}
		};

		setupAudio().catch(onError);

		return () => {
			if (mediaSourceRef.current?.readyState === 'open') {
				mediaSourceRef.current.endOfStream();
			}
		};
	}, [onError]);

	// Handle streaming
	useEffect(() => {
		const handleStream = async () => {
			try {
				const reader = stream!.getReader();
				let isFirstChunk = true;

				while (true) {
					const { done, value } = await reader.read();

					if (done) {
						break;
					}

					await appendChunk(
						sourceBufferRef.current!,
						value,
						isFirstChunk,
						audioRef.current,
						onError,
					);
				}

				if (mediaSourceRef.current?.readyState === 'open') {
					mediaSourceRef.current.endOfStream();
				}
			} catch (e) {
				onError(e as Error);
			}
		};

		if (stream && isMediaSourceReady && sourceBufferRef.current) {
			void handleStream();
		}
	}, [stream, onError, isMediaSourceReady]);

	return { audioRef };
};
